@font-face
{
    font-family: ByAnn;
    font-weight: 400;
    font-style: normal;
    src:
        url('../assets/fonts/byAnn/jennasue-webfont.woff2?v=3') format('woff2'),
        url('../assets/fonts/byAnn/jennasue-webfont.woff?v=3') format('woff'),
        url('../assets/fonts/byAnn/jennasue-webfont.ttf?v=3') format('truetype');
}

@font-face
{
    font-family: ProductByAnn;
    font-weight: 400;
    font-style: normal;
    src:
        url('../assets/fonts/productByAnn/champagneandlimousines-webfont.woff2?v=1') format('woff2'),
        url('../assets/fonts/productByAnn/champagneandlimousines-webfont.woff?v=1') format('woff'),
        url('../assets/fonts/productByAnn/champagneandlimousines-webfont.ttf?v=1') format('truetype');
}