.table-hover {
    font-size: 1rem;
    cursor: pointer;

    th, td {
        font-weight: normal;
        border-top: 1px solid $light-grey;
    }

    tbody tr{
        &:hover{
            background-color: $light-grey3;
        }
    }

    a {
        color: inherit;
    }

    .edit-icon {
        color: $blue;
        font-size: 1.25rem;
    }

    .change-up {
        color: $red;
    }

    .change-down {
        color: $green;
    }

    a:hover {
        text-decoration: none;
    }
}