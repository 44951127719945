html {
    height: 100%;
    font-size: 100%;
}

body {
    font-family: 'brandon-grotesque', system-ui, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', 'Noto Color Emoji';
    color: $font-color;

    //to enable image right-left
    overflow-x: hidden;

    height: 100%;
}

.bg-grey{
    background-color: $light-grey;
}

main{
    position: relative;

    .view{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        z-index: 20;
    }

    .view-enter {
        transform: translateX(100%);
    }

    .view-enter-active {
        transform: translateX(0);
        transition: transform 500ms;
    }

    .view-exit {
        transform: translateX(0);
    }

    .view-exit-active {
        transform: translateX(100%);
        transition: transform 500ms;
    }

    .bg-grey{
        background-color: $light-grey;
    }

    .bg-white{
        background: $white;
        border-bottom: 1px solid $white;
    }

    .modalContainer{
        transition: transform 500ms;
        transform: translateX(0);
        height: 100%;
    }

    .showModal{
        transform: translateX(-50%);
        overflow: hidden !important;
    }
}

header{
    padding: 16px 0;

    @include media-breakpoint-up(md){
        padding: 32px 0 16px 0;
    }
}

section {
    padding: 2rem 0;

    // @include media-breakpoint-up(lg) {
    //     padding: 6.563rem 0;
    // }

    &.border-bottom {
        border-bottom: 2px solid $light-blue !important;
    }
}

.container {
    @include media-breakpoint-up(sm) {
        padding: 0;
    }
}

.no-gutters {
    margin-right: -15px;
    margin-left: -15px;
    @include media-breakpoint-up(sm) {
        margin-right: 0;
        margin-left: 0;
    }
}

.clearfix {
    clear: both;
}

.white-box{
    background: $white;
    box-shadow: 0px 4px 6px rgba($black, 0.1);
    border-radius: $border-radius;
    padding: 1rem;
    margin: 0 0 1rem 0;
    font-size: 18px;
    color: $font-color;
    text-decoration: none !important;
    overflow: hidden;

    // @include media-breakpoint-up(md) {
    //     padding: 2rem;
    // }

    &.h-100{
        height: calc(100% - 1rem) !important;
    }

    &.meal-box{
        @include media-breakpoint-up(md) {
            padding: 1rem;
        }
    }
}

//preloader
.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($white, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $zIndexLoader;

    &.no-fixed {
        position: absolute;
    }

    img {
        flex: 0 1 auto;
    }

    .spinner-border {
        border: 0.25rem solid $pink;
        border-right-color: transparent;
    }
}

//placeholder
.placeholder {
    background: $light-grey;
    background-image: linear-gradient(
        to right,
        $light-grey 0%,
        darken($light-grey, 4%) 20%,
        $light-grey 40%,
        $light-grey 100%
    );
    background-repeat: no-repeat;
    background-size: 960px 480px;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;

    color: transparent;
    user-select: none;

    &:before {
        display: none;
    }
}

@keyframes placeholderShimmer {
    0% {
        background-position: -480px 0;
    }

    100% {
        background-position: 960px 0;
    }
}

.modal-body {
    padding: 0;

    h3 {
        font-size: 1.5rem;
        color: $black;
    }

    p {
        font-size: 1.2rem;
    }

    .close {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
    }
}

.svg-inline {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
    max-width: 1.5rem; // ie11 hack
}

.SVGInline {
    svg {
        font-size: inherit;
        height: 1em;
        max-width: 1.5rem; // ie11 hack
        vertical-align: -0.125em;
    }

    &.auto-height {
        svg {
            height: auto;
        }
    }
}

.stay-together {
    position: absolute;
    top: 0;
    left: 0;
}

.full-height {
    position: relative;
    height: 100vh;
    padding: 1rem 0;

    @include media-breakpoint-up(md){
        height: calc(100vh - 80px - 55px);
    }
}

.full-height-with-timeline {
    height: calc(100vh - 80px - 55px);
    padding: 1rem 0;

    @include media-breakpoint-up(md){
        height: calc(100vh - 80px - 55px);
    }
}

.print-table {
    margin: 2rem auto 0 auto;

    @media print {
        margin: auto;
    }

    thead {
        @media print {
            display: table-header-group;
        }
    }

    tfoot {
        @media print {
            display: table-footer-group;
        }
    }
}

.border-md-top{
    border: none;

    @include media-breakpoint-up(md){
        border-top: 1px solid $light-grey;
    }
}


hr{
    margin: 1.5rem 0;
    border-top: 1px solid $light-grey;
}

.sticky-footer{
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 8px 0 !important;
    background: $white;
    border-top: none;
    z-index: 3;
    border-top: 1px solid $light-grey;

    @include media-breakpoint-up(md){
        padding: 24px 0 !important;
    }

    @include media-breakpoint-up(xl){
        padding: 32px 0 !important;
    }
}

.embed-responsive{
    border-radius: $border-radius;
}

.progress {
    border-radius: 0;
    margin: 1rem 0;
    background-color: $light-pink2;
    border-radius: 3px;
    height: 0.3rem;

    .progress-bar {
        background: $pink;
    }
}
