@import './elements/range';

.form-group{
    label{
        color: $font-color;
        font-size: rem-calc(18);
        font-weight: 500;
        display: block;
        text-align: left;

        sup{
            color: $red
        }
    }

    .form-control{
        padding: 0;
        border-radius: $border-radius;
        background: $input-bg;
        border: 1px solid $input-border;
        height: auto;
        color: $navy-blue;
        overflow: hidden;

        &.is-invalid{
            border: 1px solid $red;
            
            .form-control{
                color: $red;
                background: lighten($red, 53%);
            }

            .input-group-append{
                color: $red;
                background: lighten($red, 53%);
            }

            .input-group-text{
                color: $red;
            }
        }
    
        input{
            border: none;
            background: none;
            padding: 0.375rem 1rem;
            height: 40px;

            &::-webkit-date-and-time-value{ 
                text-align:left;
                line-height: 24px;
            }

            &:disabled{
                cursor: not-allowed;
            }
        }

        textarea{
            border: none;
            background: none;
            padding: 0.5rem 1rem;

            &:disabled{
                cursor: not-allowed;
            }
        }

        .input-group-text {
            background: none;
            border: none;
        }

        .input-placeholder{
            flex: 1 1 auto;
            padding: 0.375rem 1rem;
        }
    }

    svg,
    .SVGInline {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0.75rem;
        pointer-events: none;
        max-width: 3.5rem;
        z-index: 3;
    }

    &.is-invalid{
        .invalid-feedback{
            display: block;
        }
    }
}

select {
    padding: 0.375rem 1rem !important;
    border: none !important;
    border-radius: $border-radius !important;
    background: transparent !important;
    appearance: none;
    height: auto !important;
    cursor: pointer;

    &::-ms-expand {
        display: none;
    }
}

.form-switch, .form-check {
    position: relative;
    border-bottom: 1px solid $light-grey;
    margin: 0;
    padding: 0;

    &:last-child{
        border-bottom: none;
    }

    label{
        margin: 0.5rem 0;
        padding-right: 3rem;
        line-height: rem-calc(32);
        font-weight: 400;
        color: $black;
    }

    input {
        display: none;
    }

    .checkbox{
        position: absolute;
        top: 4px;
        right: 0;
        width: rem-calc(24);
        height: rem-calc(24);
        border: 2px solid $grey;
    }

    .radio{
        @extend .checkbox;
        border-radius: 50%;
    }

    .switch {
        position: absolute;
        width: rem-calc(52);
        height: rem-calc(32);
        top: 0;
        right: 0;
        background-color: $grey;
        transition: 0.4s;
        border-radius: rem-calc(32);
        cursor: pointer;

        &:before {
            position: absolute;
            content: '';
            height: rem-calc(28);
            width: rem-calc(28);
            left: rem-calc(2);
            bottom: rem-calc(2);
            background-color: $white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }

        &.placeholder {
            background-color: $light-grey;
        }
    }

    input:checked ~ .checkbox {
        border-color: $blue;

        &::before{
            content: '';
            position: absolute;
            top: 4px;
            right: 4px;
            width: rem-calc(12);
            height: rem-calc(12);
            background-color: $blue;
        }
    }

    input:checked ~ .radio {
        border-color: $blue;

        &::before{
            content: '';
            position: absolute;
            top: 4px;
            right: 4px;
            width: rem-calc(12);
            height: rem-calc(12);
            border-radius: 50%;
            background-color: $blue;
        }
    }

    input:checked ~ .switch {
        background-color: $blue;

        &:before {
            transform: translateX(rem-calc(20));
        }
    }

    &.left-side{
        border-bottom: none;

        label{
            margin: 0.5rem 0;
            padding-left: 3rem;
            padding-right: 0;
            line-height: rem-calc(32);
            color: $grey;
        }

        .checkbox{
            position: absolute;
            top: 8px;
            left: 0;
            width: rem-calc(24);
            height: rem-calc(24);
            border: 2px solid $grey;
        }
    
        .radio{
            @extend .checkbox;
            border-radius: 50%;
            top: 4px;
        }

        input:checked ~ .radio {
            border-color: $blue;
    
            &::before{
                content: '';
                position: absolute;
                top: 4px;
                left: 4px;
                right: initial;
                width: rem-calc(12);
                height: rem-calc(12);
                border-radius: 50%;
                background-color: $blue;
            }
        }
    }
}

.panel{
    border-bottom: none;

    label {
        &.form-check-label{
            color: $navy-blue;
            width: 100%;
            text-align: left;
            margin: 0 0 0.5rem 0;
            margin: 10px 0;
            padding: 12px;
            background-color: $white;
            box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            
            &.checkbox {
                padding: 1rem 0 1rem 2.5rem;
            }
    
            p {
                color: $grey;
                margin: 0;
            }
    
            &.active {
                color: $white;
                strong {
                    color: $white;
                }
                p {
                    color: $white;
                }
                background-color: $navy-blue;
            }
    
            &.rounded {
                border-radius: $border-radius !important;
            }
    
            
            &.checkbox-label{
                padding-left: 3rem;

                .checkbox {
                    position: absolute;
                    top: 1rem;
                    left: 14px;
                    width: 1.5rem;
                    height: 1.5rem;
                    background-image: url('../assets/icons/checkbox-off.svg');
                    border: none;

                    &::before{
                        display: none;
                    }
                }

                input:checked ~ .checkbox {
                    background-image: url('../assets/icons/checkbox.svg');
            
                    
                }
            }

        }

        &.is-invalid {
            padding: 1rem 0 1rem 2.5rem !important;
        }
    
        sup {
            color: $magenta;
        }
    }
}

.invalid-feedback {
    margin-top: 0.25rem;
    color: $red;
    font-size: 1rem;

    li {
        list-style: none;
    }
}

ul.errors {
    text-align: left;
    font-size: 1.5rem;
    color: $red;
}

.custom-checkbox {
    .custom-control-label {
        &::before {
            border-radius: 5px;
        }
    }
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: $white;
    border-color: $pink;
    background-color: $pink;
}
