#main-menu {
    padding: 0;
    border-bottom: 1px solid $light-grey;

    .user-menu-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }

    nav.navbar {
        padding: 1rem;
        background: $white;
        transition: background-color 0.3s ease-in-out;
        border-bottom: none;

        @include media-breakpoint-up(md) {
            padding: 0;

            &.logout {
                padding: 1.65625rem 0;
            }
        }

        a.btn,
        button {
            padding: 0 2rem;
            font-size: 1.25rem;
            height: 3rem;
            line-height: 3rem;
        }

        .navbar-brand {
            padding-top: 0;
            padding-bottom: 0;

            img {
                height: 50px;
                width: auto;
            }
        }

        .dropdown {
            .dropdown-arrow {
                display: inline-block;
                transition: all 0.3s ease-in-out;
            }

            .dropdown-menu {
                font-size: 1.125rem;
                color: $navy-blue;

                opacity: 0;
                transition: opacity 0.3s ease-in-out;

                @include media-breakpoint-up(lg){
                    font-size: 1.375rem;
                }

                .dropdown-header {
                    font-size: 1.125rem;
                    color: $navy-blue;

                    @include media-breakpoint-up(lg){
                        font-size: 1.375rem;
                    }
                }

                .dropdown-item {
                    padding: 0.25rem 1.5rem;
                    height: auto;
                    line-height: normal;
                    background: $white;

                    &:hover {
                        background-color: lighten($light-grey, 2%);
                    }

                    .nav-link {
                        color: $navy-blue !important;
                        padding: 0 !important;

                        &:hover,
                        &.active {
                            color: $blue !important;
                        }
                    }
                }
            }

            &.show {
                .dropdown-arrow {
                    transform: rotate(180deg);
                }

                .dropdown-menu {
                    opacity: 1;
                }
            }
        }

        .navbar-nav {
            padding-top: 1rem;

            @include media-breakpoint-up(md) {
                padding-top: 0;
            }
        }

        .navbar-toggler {
            padding: 0;
            outline: none;
            color: $navy-blue;
            transition: color 0.3s ease-in-out;
            border-radius: 0;

            .dropdown-arrow {
                color: $navy-blue;
                display: inline-block;
                transition: all 0.3s ease-in-out;
            }
        }

        .dropdown-toggler {
            background: none;
            padding: 0;
            margin-top: 0;
            color: $navy-blue;
            box-shadow: none;
            border-radius: 0;
            border: none;
            font-size: 1.25rem;
            height: 3rem;
            line-height: 3rem;
            cursor: pointer;
            width: auto;
            font-weight: inherit;
            text-transform: inherit;

            .dropdown-arrow {
                color: $navy-blue;
                display: inline-block;
                transition: all 0.3s ease-in-out;

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        .nav-link {
            font-size: 1.125rem;
            color: $navy-blue;
            white-space: nowrap;
            transition: color 0.3s ease-in-out;

            text-align: center;
            border-top: 1px solid rgba($white, 0.2);

            &:hover {
                background-color: rgba($white, 0.1);
                border-top: 1px solid rgba($white, 0.1);
            }

            &.active {
                color: $blue;
            }

            @include media-breakpoint-up(md) {
                padding: 0;
                padding: 1.25rem 0.85rem;
                border-top: none;

                &:hover {
                    border-top: none;
                    color: $blue;
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 1.25rem 1rem;
                font-size: 1.375rem;
            }
        }

        .user-menu-collapse {
            .nav-link {
                border-top: 1px solid rgba($grey, 0.1);

                &:hover {
                    background-color: rgba($light-grey, 0.5);
                    border-top: 1px solid rgba($grey, 0.05);
                }

                @include media-breakpoint-up(md) {
                    border-top: none;

                    &:hover {
                        border-top: none;
                    }
                }
            }
        }

        .dropdown-toggler::after {
            background: transparent;
        }

        &.navbar-open {
            background-color: $blue;

            @include media-breakpoint-up(md) {
                background-color: $white;
            }

            .navbar-toggler {
                color: $white;
                background: transparent;

                .dropdown-arrow {
                    color: $white;
                    transform: rotate(180deg);
                }

                @include media-breakpoint-up(md) {
                    color: $navy-blue;
                }
            }

            .dropdown-toggler {
                color: $white;
                background: transparent;

                .dropdown-arrow {
                    color: $white;
                }

                @include media-breakpoint-up(md) {
                    color: $navy-blue;
                }
            }

            button {
                color: $blue;
                background: $white;

                @include media-breakpoint-up(md) {
                    color: $white;
                    background: $blue;
                }
            }

            .nav-link {
                color: $white;

                &.active {
                    color: $navy-blue;
                }

                @include media-breakpoint-up(md) {
                    color: $navy-blue;
                }
            }
        }
    }

    // nav.navbar-expand-md {
    //     @include media-breakpoint-up(md) {
    //         min-height: 6.5rem;
    //     }
    // }
}

.nav-item {
    .subnav {
        padding: 0;
        position: relative;
        width: 100%;
        border-bottom: 0;
        background-color: rgba($white, 0.1);
       
        .container {
            padding: 0;
        }

        @include media-breakpoint-up(md) {
            display: block;
            position: absolute;
            z-index: 100;
            left: 0;
            top: 67px;
            height: 0;
            padding: 0;
            background: $white;
            border-bottom: 1px solid $light-grey;
            transition: height 0.3s ease-in-out;
            overflow: hidden;

            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $light-grey;
            }
        }

        @include media-breakpoint-up(lg) {
            top: 73px;
        }

        .navbar-nav {
            width: 100%;
            padding-top: 0 !important;
        }

        .nav-item {
            .nav-link {
                color: $white !important;

                &.active {
                    color: $navy-blue !important;
                }

                @include media-breakpoint-up(md) {
                    padding: 1.25rem !important;
                    color: $navy-blue !important;

                    &:hover {
                        color: $blue !important;
                    }

                    &.active {
                        color: $blue !important;
                    }
                }
            }

            &.active {
                .nav-link {
                    color: $navy-blue !important;

                    @include media-breakpoint-up(md) {
                        color: $blue !important;
                    }
                }
            }
        }
    }

    &:hover {
        .subnav {
            @include media-breakpoint-up(md) {
                height: 58.5px;
            }

            @include media-breakpoint-up(lg) {
                height: 73px;
            }
        }
    }
}

.dropdown-item {
    padding: 0;

    a {
        display: block;
        width: 100%;
        padding: 0.25rem 1.5rem;
        font-size: 1.375rem;
        color: $navy-blue;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: $light-grey;
            color: $blue;
        }
    }
}
