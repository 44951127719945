footer {
    position: relative;
    // z-index: $zIndexFooter;
    padding: 3.5rem 0;
    border-top: 1px solid $light-grey;
    background: white;
    z-index: 10;

    @include media-breakpoint-up(sm) {
        padding: 6.563rem 0;
    }

    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    h1 {
        font-size: 2rem;
    }

    .nav-link {
        font-size: 1.375rem;
        color: $font-color;
        padding: 0 0 1rem 0;
    }

    p {
        font-size: 1.375rem;

        &.small{
			font-size: 16px;

			@include media-breakpoint-up(md){
				font-size: 20px;
			}
		}
    }

    img {
        margin: 0 1rem 1rem 0;
    }

    .store{
        max-width: 132px;
    }
}
