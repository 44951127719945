.page-template-cart {
    main{
        background: $light-grey3;

        section {
            padding: 0;
            border-bottom: none;
        }

        .line{
            position: relative;
            text-align: center;
            margin: 1rem 0;
            font-size: 14px;
            color: $grey2;
            
            span{
                position: relative;
                display: inline-block;
                background: $white;
                padding: 0 1rem;
            }

            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background: $grey3;
                top: 50%;
                left: 0;
            }
        }
        
        .product-thumbnail{
            width: 100px;
            height: auto;
        }
    
        .price {
            text-align: right;
        }
    
        .payment-method-form {
            .collapse.show {
                display: inline;
            }

            .payment-icon{
                width: auto;
                height: 24px;
                margin: 0 4px 4px 0;
            }
        }
    
        span.collapse-arrow {
            display: inline-block;
            color: $primary;
            transition: all 0.3s ease-in-out;
    
            &.open {
                transform: rotate(180deg);
            }
        }
    
        .cart-product {
            .product-row {
                border-bottom: 1px solid $light-grey;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 18px;
                color: $grey;

                &:nth-child(2){
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    align-items: center;
                }

                
            }
        }
    
        .cart-summary {
            .row {
                border-bottom: 1px solid $light-grey;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 18px;
                color: $grey;
    
                &.delivery-option-price {
                    border-bottom: 1px solid $primary;
                }

                &:nth-child(2){
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                    color: $navy-blue;    
                }
            }

            .summary-product {
                border-bottom: 1px solid $light-grey;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 18px;
                color: $grey;

                &:nth-child(2){
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    align-items: center;
                }
            }
        }

        .safe-shopping{
            .safe-shopping-container{
                border: 1px solid $grey3;
                border-radius: 16px;
                padding: 24px 32px 12px 32px;
                text-align: center;
                display: inline-block;

                &:after{
                    content: attr(data-title);
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: $light-grey3;
                    color: $grey2;
                    padding: 0 12px;
                    font-size: 18px;
                }

                img{
                    margin: 0 4px 12px 4px;
                }
            }
        }
    
        .payment-method {
            p {
                font-size: 14px;
                color: $grey;
            }
    
            .payment-logo {
                max-height: 55px;
            }
        }
    }
}
