.page-template-hydration
{

    $dark-blue: #115281;
    $blue: #48B3E3;
    $light-blue: #E1F6FF;
    $pink: #E56D9E;

    .full-screen {
        display: flex;
        width: 100%;
        min-height: calc(100vh - 4rem);
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            min-height: auto;
        }

        &.container{
            min-height: calc(100vh - 4rem);
        }
    }

    // main{
    //     padding: 2rem 0;
    // }

    .goal-header{
        text-align: center;
        font-size: 16px;
        margin: 0 0 20px 0;
    }

    .btn-history{
        position: absolute;
        top: 50px;
        right: 15px;
        display: inline-block;
        width: 50px;
        height: 50px;
        background: $white;
        z-index: 10;

        &.placeholder{
            filter: grayscale(100%);
        }

        @include media-breakpoint-up(md) {
            right: 0;
        }
    }

    .drop{
        position: relative;
        display: inline-block;

        &.placeholder{
            filter: grayscale(100%);
        }

        img{
            max-width: 230px;
            height: auto;
            position: relative;
            display: block;
            margin: 0 auto;
            z-index: 1;

        }
        
        .value{
            position: absolute;
            top: 0;
            left: 5%;
            width: 90%;
            height: 93%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .waves{
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0%;
                max-height: 200%;
                opacity: 0.5;
                
                z-index: 0;
                background-image: url(../../assets/icons/hydration/waves.png);
                background-position: top right;
                transition: all 1s ease-out;

                animation-name: wave;
                animation-duration: 6s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }

            .waves2{
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0%;
                max-height: 200%;
                
                z-index: 0;
                background-image: url(../../assets/icons/hydration/waves.png);
                background-position: top left;
                transition: all 1s ease-out;

                animation-name: wave2;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }

            @keyframes wave {
                from {
                    background-position: top right;
                }
                to {
                    background-position: top right 600px;
                }
            }

            @keyframes wave2 {
                from {
                    background-position: top left;
                }
                to {
                    background-position: top left 600px;
                }
            }

            .text{
                text-align: center;
                font-size: 24px;
                color: $dark-blue;
                line-height: 1.2;
                font-weight: 500;
                margin: 1rem 0 0 0;
                z-index: 1;
            }
        }
    }

    .hydration-goal{
        position: relative;
        margin: 0 0 1rem 0;
        z-index: 10;
    }

    .btn-goal{
        display: inline-block;
        font-size: 18px;
        padding: 0.25rem 1rem;
        border-radius: rem-calc(10);
        border: 1px solid $dark-blue;
        background: $white;
        color: $dark-blue !important;
        text-transform: none;
        font-weight: normal;
        width: auto;
        height: auto;
        line-height: normal;

        &:hover, &:active, &:focus{
            box-shadow: 0 0 0 0.2rem rgba($blue, 0.5) !important;
        }

        .your-goal{
            color: $blue;
            font-weight: 500;
        }

        .SVGInline{
            font-size: 16px;
            margin: 0 0 0 20px;
        }

        &.btn-placeholder {
            background: $light-grey;
            background-image: linear-gradient(
                to right,
                $light-grey 0%,
                darken($light-grey, 4%) 20%,
                $light-grey 40%,
                $light-grey 100%
            );
            background-repeat: no-repeat;
            background-size: 960px 480px;
            border: 1px solid $grey;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeholderShimmer;
            animation-timing-function: linear;
    
            color: transparent !important;

            .your-goal{
                color: transparent;
            }
        }
    }

    .btn-add{
        display: inline-block;
        background: transparent;
        font-size: 16px;
        color: $blue !important;
        text-transform: none;
        padding: 20px 10px 10px 10px;
        margin: 1rem 0;
        border: none;
        width: auto;
        height: auto;
        box-shadow: none;
        line-height: normal;

        .circle{
            position: relative;
            width: 76px;
            height: 76px;
            background: $light-blue;
            border-radius: 50%;
            margin-bottom: 10px;
            transition: all 0.3s ease-in-out;

            &.placeholder{
                filter: grayscale(100%);
            }
        }

        img.icon{
            position: absolute;
            bottom: 10px;
            left: 20px;
            width: 37px;
            height: 80px;
        }

        img.plus-icon{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 33px;
            height: 33px;
            transition: all 0.3s ease-in-out;
        }

        &::after{
            display: none;
        }

        &:hover, &:active, &:focus{
            background-color: transparent !important;
            box-shadow: none !important;

            .circle{
                animation: hydrapulse 2s infinite;
                box-shadow: 0 0 0 rgba($light-blue, 1);
            }

            @keyframes hydrapulse {
                0% {
                  box-shadow: 0 0 0 0 rgba($light-blue, 1);
                }
                70% {
                    box-shadow: 0 0 0 10px rgba($light-blue, 0);
                }
                100% {
                    box-shadow: 0 0 0 10px rgba($light-blue, 0);
                }
            }
        }

        &.btn-placeholder {
            background: $light-grey;
            background-image: linear-gradient(
                to right,
                $light-grey 0%,
                darken($light-grey, 4%) 20%,
                $light-grey 40%,
                $light-grey 100%
            );
            background-repeat: no-repeat;
            background-size: 960px 480px;
    
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeholderShimmer;
            animation-timing-function: linear;
    
            color: transparent !important;
        }
    }


    .btn-link{
        text-decoration: underline;
        text-transform: none;
        color: $pink;
        font-size: 16px;
        font-weight: normal;
        
        &:disabled{
            background: transparent;
            opacity: 0;
        }
    }

    .ct-chart {
        .ct-series {
            .ct-point {
                stroke: $blue;
            }
    
            .ct-area {
                fill: $blue;
            }
    
            .ct-bar {
                fill: $blue;
            }
        }
    }
}

.modal-dialog.hydration{

    .modal-content{
        border-radius: 10px;
        border: none;

        .modal-header{
            border-bottom: none;
        }

        .modal-body{
            padding: 0 20px;
        }

        .modal-footer{
            border-top: none;
            justify-content: center;
            padding: 20px;
        }
    }

    h2 {
        font-size: 20px;
        font-weight: normal;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md){
            font-size: 36px;
        }
    }

    h3 {
        color: $blue;
        font-size: 24px;

        .big-number {
            font-size: 36px;
            vertical-align: baseline;
            font-weight: 600;
        }
    }

    .custom-range {
        position: relative;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 46px;
        outline: none;
        padding: 0 !important;
    
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: linear-gradient(
                to right,
                $pink 0%,
                $blue var(--value),
                rgba($grey, 0.15) var(--value),
                rgba($grey, 0.15) 100%
            );
            border-radius: 5px;
        }
    
        &::-moz-range-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: linear-gradient(
                to right,
                $pink 0%,
                $blue var(--value),
                rgba($grey, 0.15) var(--value),
                rgba($grey, 0.15) 100%
            );
            border-radius: 5px;
        }
    
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 24px;
            height: 24px;
            background: $white;
            border: 4px solid $blue;
            cursor: pointer;
            margin-top: -8px;
        }
    
        &::-moz-range-thumb {
            width: 22px;
            height: 22px;
            background: $white;
            border: 4px solid $blue;
            cursor: pointer;
            margin-top: -10px;
        }
    
        &::before {
            content: attr(min);
            position: absolute;
            left: 0;
            bottom: -0.5rem;
            font-size: 14px;
            color: $blue;
        }
    
        &::after {
            content: attr(max);
            position: absolute;
            right: 0;
            bottom: -0.5rem;
            font-size: 14px;
            color: $blue;
        }
    }

    .congrats-icon{
        max-width: 140px;
    }

    .btn{
        background: $pink;
        border-radius: 20px;
        font-size: 16px;
        line-height: normal;
        width: auto;
        height: auto;
        padding: 8px 20px;
        min-width: 200px;

        &:hover, &:active, &:focus{
            background-color: darken($pink, 10%) !important;
            box-shadow: 0 0 0 0.2rem rgba($pink, 0.5) !important;
        }
    }
}
