.page-template-mindfulness {

    $navy-blue: #044389;
    $dark-navy-blue: #022B58;
    $pink: #E56D9E;

    header {
        h1 {
            font-size: 3rem;
            font-weight: 400;
            margin: 2rem 0 1rem 0;
        }
    }

    section{
        padding: 20px 0;
    }

    .col-6{
        padding: 20px;
        padding-top: 0;

        &:nth-child(odd){
            padding-right: 10px;

            @include media-breakpoint-up(sm) {
                padding: 10px;
            }
        }

        &:nth-child(even){
            padding-left: 10px;

            @include media-breakpoint-up(sm) {
                padding: 10px;
            }
        }
    }

    .box{
        position: relative;
        border-radius: 1rem;
        overflow: hidden;
        box-shadow: 0px 30px 30px -30px rgba($dark-navy-blue, 0.75);
        cursor: pointer;

        .overlay{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($navy-blue, 0.68);
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            border-radius: 1rem;
        }
        
        .duration{
            position: absolute;
            background: $white;
            border-radius: 1rem;
            top: 1rem;
            right: 1rem;
            padding: 0.25rem 1rem;
            font-size: 12px;
            font-weight: bold;

            img{
                display: block;
            }
        }

        .play-pause{
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -40px;
            margin-left: -30px;
            width: 60px;
            height: 60px;
        }

        .title{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 1rem;
            background: rgba($dark-navy-blue, 0.75);
            text-align: left;
            color: $white;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
            border-radius: 0 0 1rem 1rem;
        }


        &.active{
            .overlay{
                opacity: 1;
            }
        }
    }

    .audio-player{
        position: sticky;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $dark-navy-blue;
        color: $white;
        border-top: 1px solid $white;

        .container{
            padding-right: 0;
            padding-left: 0;

            @include media-breakpoint-up(sm) {
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        .thumbnail{
            width: 69px;
            height: 69px;
            background-color: $pink;
            background-size: cover;
            background-position: center;
        }

        .duration{
            text-align: left;
            color: $pink;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.2;
        }

        .title{
            text-align: left;
            color: $white;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
            max-width: calc(100vw - 200px);
            white-space: nowrap;
            overflow: hidden;
        }

        button{
            padding: 0;
            border: none;
            background: none;
            outline: none;
        }
    }
}
