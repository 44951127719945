.page-template-cms-article {
    header {
        padding: 2rem 0;

        .back-btn {
            position: relative;
            transform: translateX(-50%);
        }

        h1 {
            font-size: 2rem;
            font-weight: 900;
            margin-bottom: 0;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: 3rem;
            }
        }
    }

    .article-header {
        padding: 0 0 2rem 0;
        border-bottom: 1px solid #efefef;

        @include media-breakpoint-up(md) {
            padding: 0.8rem 0;
        }

        .article-details {
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 0;
            text-align: center;
            position: relative;
            left: 50%;
            transform: translateX(-100%);
        }
    }

    .category {
        font-size: 14px;
        padding: 3px 12px;
        border-radius: 8px;
        color: $white;
        margin: 2rem 0;
        display: inline-block;

        &.dieta {
            background-color: $green2;
        }

        &.motywacja {
            background-color: $violet2;
        }

        &.porady {
            background-color: $blue2;
        }
        
        &.ćwiczenia {
            background-color: rgba($navy-blue, 0.5);
        }
    }

    .article-title {
        margin-bottom: 2rem;
        font-size: 2.31rem;
        font-weight: 900;

        @include media-breakpoint-up(md) {
            font-size: 3rem;
        }
    }

    .article-img {
        margin-bottom: 2rem;
        span {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .blockquote {
        position: relative;
        padding-left: 5rem;
        border-left: 2px solid $primary;
        quotes: '\201C''\201D''\2018''\2019';

        &:before {
            content: '“';
            position: absolute;
            font-family: cursive;
            top: -2rem;
            left: 1rem;
            color: $primary;
            font-size: 6.25rem;
        }
    }

    p.sign {
        position: relative;
        text-align: right;
        margin: 1rem 0 1rem 0;
        color: $blue;
    }

    .article-lead,
    .article-summary {
        margin: 2rem 0;
        font-size: 1.5rem;
    }

    hr {
        //max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }

    .cms-content, .article-lead {
        //max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;

        @include media-breakpoint-up(md){
            font-size: 1.5rem;
        }

        p {
            margin-bottom: 2rem;
            color: $navy-blue;
            font-size: 18px;

            @include media-breakpoint-up(md){
                font-size: 1.5rem;
            }

            img {
                margin: 0 auto;
                display: block;
            }
        }

        h1 {
            text-align: center;
        }

        h2 {
            text-align: center;
        }

        h3 {
            color: $black;
        }

        ul {
            padding: 0;
            list-style: none;
            margin-bottom: 0;

            li {
                position: relative;
                padding: 0 0 0 1rem;

                @include media-breakpoint-up(md){
                    font-size: 1.25rem;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 14px;
                    transform: translateY(-50%);
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 50%;
                    background-color: $pink;

                    @include media-breakpoint-up(lg) {
                        top: 20px;
                    }
                }

                &::marker{
                    color: transparent;
                }

                p {
                    margin: 0;
                }
            }
        }

        ol {
            padding: 0 0 0 2rem;
            margin-bottom: 2rem;

            @include media-breakpoint-up(md){
                font-size: 1.25rem;
            }

            li {
                padding: 0 0 0 1rem;
                color: $navy-blue;

                ol{
                    margin-bottom: 0;
                }
            }

            p{
                margin: 0;

                @include media-breakpoint-up(md){
                    font-size: 1.25rem;
                }
            }
        }

        .faq-section {
            padding: 0 0 1rem 0;
            border: none;

            .collapse-item {
                border-bottom: 1px solid $light-grey;

                &:last-child {
                    border-bottom: none;
                }

                .collapsing,
                .collapse {
                    color: #8f8f8f;
                }
            }

            h3 {
                font-size: 1.5rem;
                margin: 1rem 0;
                color: $black;
                padding-right: 2rem;
                position: relative;

                button {
                    color: $navy-blue;
                    font-weight: 500;
                    text-align: left;
                    display: block;
                    width: 100%;
                    outline: none;
                }

                span.collapse-arrow {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: $pink;
                    font-size: 1.5rem;
                    height: 100%;
                    transition: all 0.3s ease-in-out;

                    &.open {
                        transform: rotate(180deg);
                    }
                }
            }

            .card {
                border: none;

                .card-body {
                    padding: 0 0 1rem 0;
                    font-size: 1.375rem;
                    text-align: left;

                    p {
                        font-size: 1.375rem;
                    }
                }
            }
        }

        table {
            font-size: 1rem;
            color: $black;

            @include media-breakpoint-up(md){
                font-size: 1.25rem;
            }

            p{
                color: $black;
            }

            th, td{
                padding: 0.5rem;
            }
        }

        .pageActions {
            text-align: center;
        }

        aside {
            &.right {
                float: right;
                margin-left: 1rem;
                margin-bottom: 1rem;
            }

            &.left {
                float: left;
                margin-right: 1rem;
                margin-bottom: 1rem;
            }
        }
    }
    .product {
        border-radius: 10px;
        padding: 10px;
        border: 1px solid $blue;
        color: $blue;

        .product-bestseller {
            text-align: center;
            font-size: 36px;
        }

        .card-header {
            background: transparent;
            border-bottom: none;
            font-weight: bold;
            font-size: 24px;
            line-height: 34px;
            text-align: center;
        }

        .card-body {
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 390;
            padding: 0;

            .product-price {
                font-size: 36px;
                line-height: 51px;
            }

            .product-description {
                font-size: 24px;
                line-height: 34px;
            }
        }

        .card-footer {
            background: transparent;
            border-top: none;
            margin: 0 auto;
        }
    }
}
