.page-template-shopping-list {
    .products-category {
        margin-bottom: 2rem;
    }

    .ingredients {
        font-size: rem-calc(18);
        list-style: none;
        margin: 0 0 1.5rem 0;
        padding: 0;

        li > button {
            display: flex;
            justify-content: space-between;
            position: relative;
            margin: 0.75rem 0;
            padding: 0 2.5rem 0 0;
            cursor: pointer;
            width: 100%;
            max-height: 7rem;
            transition: all 0.3s;
            color: $navy-blue;
            text-align: left;

            appearance: none;
            outline: none;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 1.5rem;
                height: 1.5rem;
                background-image: url('../../assets/icons/checkbox-off.svg');
            }

            &.done {
                &:before {
                    content: '';
                    background-image: url('../../assets/icons/checkbox.svg');
                }
            }

            &.hide-down {
                transform: translateY(5rem);
                opacity: 0;
                margin: 0 0 -1rem 0;
                max-height: 0;
            }

            &.hide-up {
                transform: translateY(-7rem);
                opacity: 0;
                margin: -1rem 0 0 0;
                max-height: 0;
            }

            &.show {
                animation-name: show;
                animation-duration: 0.3s;
                overflow: hidden;
            }
        }

        @keyframes show {
            0% {
                max-height: 7rem;
                opacity: 0;
            }

            1% {
                max-height: 0;
            }

            100% {
                max-height: 7rem;
                opacity: 1;
            }
        }
    }

    .bought-section{
        background: $light-grey3;
        border-radius: $border-radius;
        padding: 0;
    }

    .bought-button {
        position: relative;
        margin: 0;
        color: $pink;
        padding: 0.625rem 1rem;
        max-width: none;
        width: 100%;
        background: transparent;
        border: none;
        text-transform: inherit;
        text-align: inherit;
        font-size: rem-calc(20);
        font-weight: 400;
        appearance: none;

        &:focus,
        &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
            border: none;
            background: transparent;
            color: $pink;
        }

        span.collapse-arrow {
            display: flex;
            align-items: center;
            position: absolute;
            right: 1rem;
            top: 0;
            color: $pink;
            font-size: 1rem;
            height: 100%;
            transition: all 0.3s ease-in-out;

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .card {
        position: relative;
        border: none;
        background: transparent;
        margin: 0;

        .card-body {
            padding: 0 1rem;
            text-align: left;

            .ingredients {
                margin: 0;
                opacity: 0.5;
            }

            p {
                margin: 0 0 rem-calc(10) 0;
                color: $secondary;
                font-style: italic;
                font-size: rem-calc(14);
            }
        }
    }
}
