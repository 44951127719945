.page-template-workouts-balance {
    .buttons-wrapper {
        img {
            height: 55px;

            @include media-breakpoint-down(sm) {
                height: 40px;
            }
        }
    }
}
