.alert{
    position: relative;
    background: $white;
    border-radius: $border-radius;
    padding: 12px 16px;
    margin: 0 0 16px 0;
    font-size: 16px;
    color: $font-color;
    text-decoration: none !important;
    text-align: left;
    border: 1px solid $navy-blue;

    &.alert-info{
        border-color: $navy-blue;
    }

    &.alert-info-pink{
        background: rgba($light-pink, 0.15);
        border-color: $pink;
        p {
            color: $pink;
        }
    }

    &.alert-info-navy{
        background: rgba($light-blue, 0.2);
        border-color: $navy-blue;
        p {
            color: $navy-blue;
        }
    }

    &.alert-success{
        border-color: $navy-blue;
    }

    &.alert-danger{
        border-color: $pink;
        color: $pink;
    }

    h4{
        font-size: 16px;
        font-weight: 500;
    }

    p{
        font-size: 16px;
    }

    ul{
        font-size: 16px;
        list-style: none;
        
        padding: 0;
        margin: 0;

        li{
            padding-left: 16px;
            position: relative;

            &::before {
                content: "\2014";
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

.question-answer{
    display: block;
    border-radius: $border-radius;
    padding: 0.675rem 1rem;
    font-size: 18px;
    font-weight: 400;
    color: $navy-blue;

    &.right-answer{
        color: $green;
    }

    &.correct{
        color: $green;
        background: rgba($green, 0.05);
        background-image: url('../assets/icons/true.svg');
        background-position: right 0.5rem center;
        background-repeat: no-repeat;
    }

    &.incorrect{
        color: $red;
        background: rgba($red, 0.05);
        background-image: url('../assets/icons/false.svg');
        background-position: right 0.5rem center;
        background-repeat: no-repeat;
    }
}
