@charset "UTF-8";

.page-template-fav-meals {
    height: 100%;

    main {
        position: relative;

        .modalContainer{
            transition: transform 500ms;
            transform: translateX(0);
            height: 100%;
        }

        .showModal {
            transform: translateX(-50%);
            overflow: hidden !important;
        }

        .w-md-auto {
            @include media-breakpoint-up(md) {
                width: auto !important;
            }
        }

        // day plan new styles
        .add-remove-fav {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 0;
        }

        .meal {
            position: relative;
            margin: 0 0 1rem 0;

            @include media-breakpoint-down(sm) {
                padding-left: 4px;

                &:nth-child(2n + 1) {
                    padding-left: 15px;
                    padding-right: 4px;
                }
            }

            .white-box {
                position: relative;
                padding: 0 16px 16px 16px;
                overflow: hidden;

                .embed-responsive {
                    margin: 0 -16px 16px -16px;
                    width: calc(100% + 32px);
                    border-radius: 0;

                    .embed-responsive-item {
                        background-size: cover;
                        background-position: center;
                    }
                }

                .meal-title {
                    height: 38px;
                    overflow: hidden;
                    margin: 0 0 1rem 0;

                    // trim to 2 lines
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    @include media-breakpoint-up(md) {
                        height: 48px;
                    }
                }

                h3 {
                    font-size: 16px;
                    font-weight: 500;
                    margin: 0;

                    @include media-breakpoint-up(md) {
                        font-size: 20px;
                    }
                }

                p.BTW {
                    font-size: 16px;
                    color: $font-color;
                    margin: 0 0 1rem 0;

                    img {
                        vertical-align: text-bottom;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                    }
                }
            }
        }

        .top-bar {
            h1 {
                font-size: 30px;
                font-weight: 400;

                strong {
                    font-weight: 500;
                }
            }
        }

        .meals-filters {
            position: sticky;
            top: 0;
            background: $white;
            padding: 0;
            z-index: 10;
            overflow-x: auto;
            border-bottom: 1px solid #dee2e6;

            @include media-breakpoint-up(md) {
                border-bottom: none;
                box-shadow: 0px 4px 6px rgba($black, 0.1);
            }

            &.active {
                transition: all 0.4s ease-in-out;
            }

            &.hidden {
                top: 0;
                transition: all 0.4s ease-in-out;
            }

            .container {
                padding: 0;
            }

            ul {
                text-align: center;
                white-space: nowrap;
                margin: 0;
                padding: 0;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    display: none; // Safari and Chrome
                }

                @include media-breakpoint-up(sm) {
                    overflow: hidden;
                }

                .nav-item {
                    display: inline-block;
                }
            }

            .nav-link {
                padding: 12px 12px 10px 12px;
                color: $grey;
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                white-space: nowrap;
                cursor: pointer;
                border-bottom: 2px solid $white;

                @include media-breakpoint-up(md) {
                    padding: 12px 24px 10px 24px;
                }

                &:active,
                &:hover {
                    color: $grey;
                }

                &.active {
                    color: $pink;
                    border-color: $pink;
                }
            }
        }

        //meal-details
        .meal-details {
            padding-top: 0;
            position: relative;

            @include media-breakpoint-down(sm) {
                padding-bottom: 0;
            }

            .sticky-video {
                position: sticky;
                top: 138px;

                @include media-breakpoint-up(md) {
                    min-height: calc(100vh - 100px);
                    background-image: linear-gradient(
                        to bottom,
                        $light-grey,
                        $white
                    );
                }
            }

            .embed-responsive {
                margin: 0 -15px;
                width: calc(100% + 30px);
                border-radius: 0;

                @include media-breakpoint-up(md) {
                    margin: 24px 0 0 0;
                    width: 100%;
                    border-radius: 10px;
                }

                .video {
                    background-color: $grey;
                }

                .embed-responsive-item {
                    background-size: cover;
                    background-position: center;
                }

                .label {
                    position: absolute;
                    bottom: 1rem;
                    width: auto;
                    display: inline-block;
                    background: $light-grey;
                    border-radius: $border-radius;
                    padding: 4px 24px;
                    font-size: 16px;
                    font-weight: 400;
                    color: $navy-blue;
                }
            }

            .meal-info {
                position: relative;
                padding-top: 24px;
                font-size: 14px;
            
                @include media-breakpoint-up(md) {
                    font-size: 18px;
                }

                h1 {
                    font-size: 30px;
                    font-weight: 700;
                    text-align: center;
                    margin-bottom: 2rem;

                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }
                }

                p {
                    font-size: 16px;
                    margin: 24px 0 0 0;
                    text-align: center;
                    color: $navy-blue;

                    @include media-breakpoint-up(md) {
                        padding: 24px 0 0 0;
                        border-top: 1px solid $light-grey;
                        text-align: left;
                        font-size: 18px;
                    }

                    a {
                        display: inline-block;
                        border: 1px solid $light-grey;
                        padding: 2px 10px;
                        border-radius: $border-radius;
                        color: $font-color;
                        font-weight: 500;
                        margin: 0 5px;
                        font-size: 16px;
                    }
                }

                .action-buttons {
                    position: sticky;
                    top: 100%;
                }
            }

            .dish {
                border-top: 1px solid $light-grey;
                margin-top: 24px;
                padding-top: 32px;

                .col-md-5 {
                    border-top: 1px solid $light-grey;
                    margin-top: 24px;
                    padding-top: 32px;

                    @include media-breakpoint-up(md) {
                        margin-top: 0;
                        padding-top: 0;
                        border-top: none;
                    }
                }
            }

            h2 {
                font-size: 22px;
                font-weight: 700;
                text-align: center;
                margin-bottom: 1.875rem;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }

                &.dishName {
                    font-size: 30px;
                    margin-bottom: 2rem;
                }
            }

            .ingredients {
                font-size: 18px;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    position: relative;
                    margin: 0 0 16px 0;
                    cursor: pointer;
                }
            }

            .btn-change {
                vertical-align: middle;
                display: inline-block;
                margin: -2px 8px 0 0;
                width: 20px;
                height: 20px;
                padding: 0;
                outline: none;

                .img-fluid {
                    vertical-align: top;
                }
            }

            .collapse,
            .collapsing {
                margin-left: -24px;
            }

            .card {
                position: relative;
                margin-top: 16px;

                border-radius: 10px;
                border: none;
                background: rgba($pink, 0.15);
                z-index: 0;

                .card-body {
                    padding: 16px;
                    font-size: 16px;
                    color: $primary;
                    text-align: center;

                    h4 {
                        font-size: 20px;
                        font-weight: normal;
                        color: $pink;
                    }

                    .ingredients {
                        text-align: left;
                        color: $font-color;
                        font-size: 16px;
                        margin: 0;

                        li {
                            padding-left: 24px;
                        }

                        button {
                            color: $font-color;
                        }
                    }
                }
            }

            .product-box {
                border: 1px solid $primary;
                border-radius: $border-radius;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                margin-top: 16px;
                margin-left: -24px;

                .foods-logo {
                    display: inline-block;
                    vertical-align: middle;
                    white-space: nowrap;
                    line-height: 3rem;
                    font-family: ProductByAnn, sans-serif;
                    color: $red;

                    .by-ann {
                        font-family: ByAnn, serif;
                        color: $blue;
                    }
                }
            }

            h3 {
                font-size: 20px;
            }

            .recipe {
                h2 {
                    font-size: 22px;
                    font-weight: 700;
                    text-align: center;

                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }
                }

                ol {
                    font-size: 16px;
                    padding: 0 0 0 40px;
                    list-style: none;
                    counter-reset: recipeCounter;

                    li {
                        position: relative;
                        counter-increment: recipeCounter;
                        margin-bottom: 16px;

                        &:last-child {
                            border-bottom: none;
                            padding-bottom: 0;
                        }

                        &::before {
                            content: counter(recipeCounter);
                            position: absolute;
                            top: 0;
                            left: -40px;
                            width: 28px;
                            height: 28px;
                            border-radius: 50%;
                            background: $light-grey;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 600;
                            color: $font-color;
                            line-height: 28px;
                        }
                    }
                }
            }

            footer {
                position: sticky;
                bottom: 0;
                width: 100%;
                padding: 8px 0 !important;
                background: $white;
                box-shadow: 0px -4px 6px rgba($black, 0.1);
                border-top: none;
                margin-top: 2rem;

                img {
                    margin: 0;
                }
            }
        }

        .search {
            padding: 1rem 0 0 0;

            h3 {
                font-size: 20px;
                font-weight: 600;
            }

            hr {
                margin: 32px 0;
            }

            label {
                font-weight: 500;
            }
        }

        .alert-new {
            position: relative;
            background: $white;
            border-radius: $border-radius;
            padding: 16px 16px 16px 24px;
            margin: 0 0 16px 0;
            font-size: 16px;
            color: $font-color;
            text-decoration: none !important;
            text-align: left;
            border: 1px solid $navy-blue;

            &.info {
                border-color: $navy-blue;
            }

            &.success {
                border-color: $navy-blue;
            }

            &.danger {
                border-color: $pink;
                color: $pink;
            }

            &.neutral {
                border-color: $navy-blue;
            }

            h4 {
                font-size: 16px;
                font-weight: 500;
            }

            p {
                font-size: 16px;
            }

            ul {
                font-size: 16px;
                list-style: none;

                padding: 0;
                margin: 0;

                li {
                    padding-left: 16px;
                    position: relative;

                    &::before {
                        content: '\2014';
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            background: rgba($black, 0.5);

            &.view-enter {
                transform: translateY(0);
                opacity: 0;
            }

            &.view-enter-active {
                transform: translateY(0);
                opacity: 1;
                transition: all 500ms;
            }

            &.view-exit {
                transform: translateY(0);
                opacity: 1;
            }

            &.view-exit-active {
                transform: translateY(0);
                opacity: 0;
                transition: all 500ms;
            }
        }

        .select-insert-date {
            text-align: center;
            position: fixed;
            bottom: 0;
            left: 1rem;
            right: 1rem;
            padding: 1rem 0 !important;
            background: $white;
            box-shadow: 0px -4px 6px rgba($black, 0.1);
            border-radius: $border-radius $border-radius 0 0;
            z-index: 100;

            &.view-enter {
                transform: translateY(100%);
            }

            &.view-enter-active {
                transform: translateY(0);
                transition: transform 500ms;
            }

            &.view-exit {
                transform: translateY(0);
            }

            &.view-exit-active {
                transform: translateY(100%);
                transition: transform 500ms;
            }

            p {
                color: grey;
                font-size: 1rem;
            }

            .btn-white {
                color: $navy-blue;
            }

            label {
                display: inline-block;
                position: relative;
                font-size: 1.2rem;
                font-weight: 500;
                width: auto;
                margin: 0;

                input.hidden-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    width: 320px;
                    height: 100%;
                    border: 0;
                    overflow: hidden;
                    cursor: pointer;
                    z-index: 10;

                    &::-webkit-calendar-picker-indicator {
                        position: absolute;
                        top: -150%;
                        left: -150%;
                        width: 300%;
                        height: 300%;
                        cursor: pointer;
                    }
                }
            }

            .pointer-events-none {
                pointer-events: none;
            }

            .form-group {
                margin-bottom: 0;
            }
        }
    }
}
