.ct-chart {
    .ct-series {
        .ct-line {
            stroke: $blue;
            stroke-width: 2px;

            @include media-breakpoint-up(sm) {
                stroke-width: 3px;
            }
        }

        .ct-point {
            stroke: $blue;
            stroke-width: 5px;
            stroke-linecap: disc;

            @include media-breakpoint-up(sm) {
                stroke-width: 10px;
            }
        }

        .ct-area {
            fill: $blue;
        }

        .ct-bar {
            stroke: none;
            fill: $blue;
            width: 1rem;
            width: 1vw;

            @include media-breakpoint-up(sm) {
                width: 2rem;
                width: 1.5vw;
            }

            @include media-breakpoint-up(lg) {
                width: 1rem;
            }

            @supports (transform-box: fill-box) {
                transform-box: fill-box;
                transform: translateX(-50%);
            }
        }
    }

    /**
     * I would much rather use `@namespace ct "http://gionkunz.github.com/chartist-js/ct";`
     * and use [ct|series-name="goal"], but it does not work in SCSS 😒
     */
    .ct-series[*|series-name="goal"] {
        .ct-line {
            stroke: $green;
            stroke-dasharray: 5px 2px;
            animation: dashoffset-seven 500ms infinite linear;

            @include media-breakpoint-up(sm) {
                stroke-dasharray: 10px 3px;
                animation: dashoffset-thirtheen 500ms infinite linear;
            }
        }
    }
}

@keyframes dashoffset-seven {
    0% {
        stroke-dashoffset: 7px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}

@keyframes dashoffset-thirtheen {
    0% {
        stroke-dashoffset: 13px;
    }
    100% {
        stroke-dashoffset: 0px;
    }
}