.page-template-thnk-you {
    .hero {
        height: 190px;
        background-image: url(../../assets/images/thankyou/top_img_mobile@2x.jpeg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;

        @include media-breakpoint-up(md){
            height: 280px;
            background-image: url(../../assets/images/thankyou/top_img_desktop@2x.jpg);
        }
    }

    .ok{
        vertical-align: middle;
        margin: 0 10px 5px 0;
    }

    ol {
        padding: 0 0 0 2.5rem;
        counter-reset: item;
        list-style: none;

        li {
            position: relative;
            font-size: 20px;
            font-weight: 500;
            color: $navy-blue;
            margin-bottom: 2rem;

            &::before{
                content: counter(item);
                counter-increment: item;
                position: absolute;
                top: 0;
                left: -2.5rem;

                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                border: 1px solid #B4C7DC;
                text-align: center;
                line-height: 1.8rem;
            }

            p{
                color: $grey2;
                font-weight: 400;
                margin: 0.5rem 0 1rem 0;

                strong{
                    color: $black;
                }
            }
        }
    }

    .flyer {
        padding: 1rem;
        background: $light-grey3;
        border-radius: $border-radius;
        margin: 0 0 2rem 0;
    }
}
