.page-template-diet-settings {
    .full-screen {
        width: 100%;
        min-height: 100vh;
    }

    span.pill {
        display: inline-block;
        background: $magenta;
        color: #fff;
        border: none;
        border-radius: $border-radius;
        margin: 0 0 0 0.5rem;
        padding: 0 0.5rem;
        font-size: 14px;
    }
}
