.page-template-activities {
    main{
        position: relative;

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            background: rgba($black, 0.5);

            &.view-enter {
                transform: translateY(0);
                opacity: 0;
            }

            &.view-enter-active {
                transform: translateY(0);
                opacity: 1;
                transition: all 500ms;
            }

            &.view-exit {
                transform: translateY(0);
                opacity: 1;
            }

            &.view-exit-active {
                transform: translateY(0);
                opacity: 0;
                transition: all 500ms;
            }
        }

        .form-check {
            label {
                color: $navy-blue !important;
            }
        }

        .select-insert-date {
            text-align: center;
            position: fixed;
            bottom: 0;
            left: 1rem;
            right: 1rem;
            padding: 1rem 0 !important;
            background: $white;
            box-shadow: 0px -4px 6px rgba($black, 0.1);
            border-radius: $border-radius $border-radius 0 0;
            z-index: 100;

            &.view-enter {
                transform: translateY(100%);
            }

            &.view-enter-active {
                transform: translateY(0);
                transition: transform 500ms;
            }

            &.view-exit {
                transform: translateY(0);
            }

            &.view-exit-active {
                transform: translateY(100%);
                transition: transform 500ms;
            }

            p {
                color: grey;
                font-size: 1rem;
            }

            .btn-white {
                color: $navy-blue;
            }

            label {
                display: inline-block;
                position: relative;
                font-size: 1.2rem;
                font-weight: 500;
                width: auto;
                margin: 0;

                input.hidden-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    width: 320px;
                    height: 100%;
                    border: 0;
                    overflow: hidden;
                    cursor: pointer;
                    z-index: 10;

                    &::-webkit-calendar-picker-indicator {
                        position: absolute;
                        top: -150%;
                        left: -150%;
                        width: 300%;
                        height: 300%;
                        cursor: pointer;
                    }
                }
            }

            .pointer-events-none {
                pointer-events: none;
            }

            .form-group {
                margin-bottom: 0;
            }
        }
    }
}
